<template>
    <div class="payPage">
        <div class="centerWrapper">
            <div class="top">
                <img class="logo" :src="websiteLogo"/>
                <span>付款结算</span>
            </div>
            <div class="info">
                <div class="line1">
                    <i class="el-icon-success"></i>
                    订单提交成功，应付金额
                    <span>￥{{orderDetail.shouldReceivedAmount}}</span>
                </div>
                <div class="line2">订单付款后才会减少库存，为避免商品被抢走，请您及时付款</div>
                <div class="line4">
                    <div>订单号：{{orderDetail.orderSn}}</div>
                    <div>收货信息：{{orderDetail.provinceName+orderDetail.cityName+orderDetail.countyName+orderDetail.address}}</div>
                </div>
                <div class="line5" v-show="account1.accountNumber">
                    <div>收款商家：{{orderDetail.receivingMerchant}}</div>
                    <div>收款账号：{{account1.accountNumber}}</div>
                </div>
            </div>
            <div class="ope">
                <el-button type="primary" plain @click="backToOrder">返回订单</el-button>
            </div>
            <div class="payBox">
                <div class="wayItem" :class="{'expand':radio=='2'}" v-if="account2.accountNumber">
                    <div class="way">
                        <div class="left">
                            <el-radio v-model="radio" label="2">
                                <div class="inner">
                                    <img src="../assets/imgs/alipay.webp"/>
                                    支持电脑支付
                                </div>
                            </el-radio>
                        </div>
                        <div class="price">
                            支付
                            <span>￥{{orderDetail.amount}}</span>
                        </div>
                    </div>
                    <div class="pay">
                        <div class="qr qr1">
                            <img :src="account2.qrcode"/>
                        </div>
                        <div class="tip">
                            <i class="iconfont icon-saoma"></i>
                            <div>
                                <div>打开支付宝</div>
                                <div>扫一扫即可付款</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wayItem" :class="{'expand':radio=='3'}" v-if="account3.accountNumber">
                    <div class="way">
                        <div class="left">
                            <el-radio v-model="radio" label="3">
                                <div class="inner">
                                    <img class="wx" src="../assets/imgs/wxpay.webp"/>
                                    亿万用户的选择，更快更安全
                                </div>
                            </el-radio>
                        </div>
                        <div class="price">
                            支付
                            <span>￥{{orderDetail.amount}}</span>
                        </div>
                    </div>
                    <div class="pay">
                        <div class="qr qr2">
                            <img :src="account3.qrcode"/>
                        </div>
                        <div class="tip">
                            <i class="iconfont icon-saoma"></i>
                            <div>
                                <div>打开手机微信</div>
                                <div>扫一扫即可付款</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {getOrderDetail,getReceiveAccountList} from "@/axios/api"
export default {
    data() {
        return {
            websiteLogo:"",
            radio:"",
            orderDetail:{},
            account1:{},
            account2:{},
            account3:{},
            fromPage:"",
        }
    },
    methods: {
        gainDetail(){
            getOrderDetail({
                orderId:this.$route.query.s||this.afterSaleOrderId,
                userType:this.userInfo.userType
            }).then(res=>{
                // console.log("订单详情",res);
                if(res.code==200){
                    this.orderDetail=res.data;
                    this.goodsList=res.data.orderGoodsList;
                    var totalPrice=0;
                    this.goodsList.forEach(item=>{
                        totalPrice+=parseInt(item.count)*parseFloat(item.ourShopPrice);
                        this.$set(item,'payPrice',(parseInt(item.count)*parseFloat(item.ourShopPrice)).toFixed(2))
                    })
                    this.orderTotalPrice=totalPrice.toFixed(2)

                }
            })
        },
        gainAccountList(){
            getReceiveAccountList().then(res=>{
                // console.log("收款账户列表",res);
                if(res.code==200){
                    // this.receiveAccountList=res.data;
                    res.data.forEach(item=>{
                        if(item.type==1){
                            this.account1=item;
                            return
                        }
                        if(item.type==2){
                            this.account2=item;
                            return
                        }
                        if(item.type==3){
                            this.account3=item;
                            return
                        }
                    })
                    if(this.account2.accountNumber){
                        this.radio='2'
                    }else if(this.account3.accountNumber){
                        this.radio='3'
                    }
                    
                    // this.$nextTick(()=>{
                    //     this.createQR()
                    // })
                }
            })
        },
        // 生成二维码
        createQR(url){            
            // this.receiveAccountList.forEach((item,i)=>{
            //     new QRCode(document.getElementsByClassName('qr_'+i)[0],{
            //         text:url,
            //         width:76,
            //         height:76,
            //         colorDark:"#000000",
            //         colorLight:"#ffffff"
            //     })
            // })

            if(this.account2.accountNumber){
                var qrcode1=new QRCode(document.getElementsByClassName('qr1')[0],{
                    text:this.account2.qrcode,
                    width:76,
                    height:76,
                    colorDark:"#000000",
                    colorLight:"#ffffff"
                })
            }

            if(this.account3.accountNumber){
                this.radio="3";
                
                var qrcode2=new QRCode(document.getElementsByClassName('qr2')[0],{
                    text:this.account3.qrcode,
                    width:76,
                    height:76,
                    colorDark:"#000000",
                    colorLight:"#ffffff"
                })
            }
        },

        backToOrder(){
            // // console.log(this.fromPage)
            // if(this.fromPage.indexOf('Order')!=-1){
            //     this.$router.push()
            // }else{
            //     window.close()
            // }
            
            window.close()
        }
    },
    created() {
        if(sessionStorage.websiteLogo){
            this.websiteLogo=sessionStorage.websiteLogo;
        }
        if(localStorage.AOGEYA_userInfo){
            this.userInfo=JSON.parse(localStorage.AOGEYA_userInfo);
        }

        this.gainDetail();
        this.gainAccountList();
    },
    beforeRouteEnter (to, from, next) {
        next(vm=>vm.fromPage=from.name)
    }
}
</script>

<style lang="less" scoped>
.payPage{
    height: 100%;
    padding-top: 60px;
    .top{
        display: flex;
        align-items: center;
        &>.logo{
            width: 200px;
            height: 85px;
            object-fit: cover;
        }
        &>span{
            margin-left: 37px;
            font-size: 24px;
            font-weight: 400;
            color: #333;
        }
    }
    .info{
        margin-top: 40px;
        .line1{
            display: flex;
            align-items: center;
            font-size: 24px;
            color: #666;
            &>span{
                color: #c84039;
            }
            &>i{
                margin-right: 15px;
                font-size: 36px;
                color: rgba(0, 128, 0,0.6);
            }
        }
        .line2{
            margin-top: 15px;
            font-size: 16px;
            color: #c84039;
            padding-left: 51px;
        }
        .line4{
            margin-top: 10px;
            padding: 15px 0;
            margin-left: 51px;
            border-top: 1px solid #e5e5e5;
            display: flex;
            font-size: 16px;
            color: #999;
            &>div{
                margin-right: 50px;
            }
        }
        .line5{
            padding-left: 51px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 400;
            color: rgba(128, 128, 128, 1);
            &>div{
                margin-right: 50px;
            }
        }
    }
    .ope{
        margin-top: 50px;
        text-align: right;
    }
    .payBox{
        margin-top: 10px;
        padding: 10px 20px;
        box-shadow: 0px 0px 6px  rgba(0, 0, 0, 0.15);
        .wayItem{
            border-bottom: 1px solid #e5e5e5;
            transition: all 0.3s;
            height: 60px;
            overflow: hidden;
            &:last-child{
                border-bottom: none;
            }
            &.expand{
                height: 370px;
            }
            .way{
                height: 60px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                // border-bottom: 1px solid #e5e5e5;
                .left{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    .el-radio{
                        margin-right: 0;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        /deep/.el-radio__label{
                            height: 100%;
                        }
                        /deep/.el-radio__inner{
                            width: 28px;
                            height: 28px;
                            &::after{
                                width: 10px;
                                height: 10px;
                            }
                        }
                    }
                    .inner{
                        position: relative;
                        padding-left: 160px;
                        height: 100%;
                        overflow: hidden;
                        display: flex;
                        align-items: center;
                        &>img{
                            width: 130px;
                            position: absolute;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            &.wx{
                                width: 150px;
                            }
                        }
                        font-size: 16px;
                        color: #999;
                    }
                }
                .price{
                    font-size: 16px;
                    color: #999;
                    &>span{
                        font-size: 20px;
                        color: #c84039;
                    }
                }
            }
            .pay{
                // height: 300px;
                height: 280px;
                margin-top: 30px;
                padding-left: 45px;
                .qr{
                    height: 200px;
                    width: 200px;
                    // background: #333;
                    /deep/img{
                        width: 200px;
                        height: 200px;
                    }
                }
                .tip{
                    margin-top: 20px;
                    // padding-left: 15px;
                    // margin-left: -10px;
                    display: flex;
                    align-items: center;
                    i{
                        color: green;
                        font-size: 50px;
                    }
                    &>div{
                        font-size: 14px;
                        color: #666;
                    }
                }
            }
        }
    }
}
</style>